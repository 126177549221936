import { Component, OnDestroy } from '@angular/core';
// Services
import { LayoutService } from 'src/app/services';
import { AuthService } from 'src/app/services/shared/auth.service';
import { AppDialogComponent } from 'src/app/components/dialog/dialog.component';
import { Location } from '@angular/common';
import { UntypedFormControl, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationExtras, ParamMap, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
/**
 * Login component
 */
@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnDestroy {
    /** form control for email. checks required and uses angulars built in email check */
    emailFormControl = new UntypedFormControl('', [
        Validators.required,
        Validators.email,
    ]);
    /** password form control, looks for just required */
    passwordFormControl = new UntypedFormControl('', [
        Validators.required
    ]);
    /** is the user mobile */
    isMobile = false;
    /** loading state */
    isLoading = false;
    /** remember me checkbox */
    rememberMe = true;

    showLockedOutBanner = false;

    hasEmail = false;
    showPass = false;

    phoneNumber = null;

    showEmailEndings = false;

    signInObervable: any = null;

    darkMode: boolean = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;

    allQueryParams: ParamMap | null = null;

    /**
     * Constructor
     * @param userService User api routes
     * @param layoutService Layout service (mobile vs non)
     * @param router Angular router
     * @param route Activated route for query params
     */
    constructor(
        private layoutService: LayoutService,
        private router: Router,
        private dialog: MatDialog,
        private route: ActivatedRoute,
        private authService: AuthService,
        private location: Location,
        public snack: MatSnackBar
    ) {
        this.isMobile = this.layoutService.device === 'mobile';
        // get the email out of the params
        this.route.queryParamMap.subscribe((params: ParamMap) => {
            if (params.get('route')) {
                this.location.go('/login');
                window.location.reload();
            }
            this.allQueryParams = params;
            if (params.get('email')) {
                this.emailFormControl = new UntypedFormControl(params.get('email'), [
                    Validators.required,
                    Validators.email,
                ]);
                this.hasEmail = true;
            }
        });
    }

    ngOnDestroy() {
        if (this.signInObervable) {
            this.signInObervable.unsubscribe();
        }
    }

    checkValidity() {
        if (this.emailFormControl.hasError('required') || this.emailFormControl.hasError('email')
            || this.isLoading || this.passwordFormControl.hasError('required')) {
            return true;
        }

        return false;
    }

    /**
     * Go to signup with the email entered
     */
    goToSignup() {
        this.router.navigate(['/landing']);
    }

    applyEmailEnding(value: any) {
        this.emailFormControl.setValue(value);
        this.showEmailEndings = false;
    }

    checkAtSign(e: any) {
        if (e && e.key === '@') {
            this.showEmailEndings = true;
            this.emailFormControl.setErrors({ required: false });
        } else if (e && (e.key === 'Tab' || e.key === 'Enter' || !this.emailFormControl.value.includes('@'))) {
            this.showEmailEndings = false;
        }
    }

    /**
     * Login the user
     */
    login() {
        if (this.checkValidity()) {
            return;
        }
        this.isLoading = true;

        this.authService
            .signIn(this.emailFormControl.value,
                this.passwordFormControl.value).then(() => {
                    this.subscribeToAuth();
                });
    }

    subscribeToAuth() {
        if (!this.signInObervable) {
            this.signInObervable = this.authService.signInObservable.subscribe((loadingResult: any) => {
                if (loadingResult) {
                    // handle the error codes that may come back here
                    if (loadingResult.name === 'UserLockedOut') {
                        this.showLockedOutBanner = true;
                    } else if (loadingResult.name === 'BadPassword') {
                        this.dialog.open(AppDialogComponent, {
                            width: '350px',
                            autoFocus: false,
                            data: {
                                primaryText: `The email address and/or password you entered doesn't match our records. Please try again.`,
                                submitText: 'Forgot Password'
                            }
                        }).afterClosed().subscribe((closedResult: any) => {
                            if (closedResult) {
                                this.sendForgotPass();
                            }
                        });
                    } else if (loadingResult.name === 'FailedPasswordAttemptWarning') {
                        const numberText = loadingResult.count === 3 ? 'two' : 'one';
                        this.dialog.open(AppDialogComponent, {
                            width: '350px',
                            autoFocus: false,
                            data: {
                                primaryText: `For security reasons your account will be locked for 20 minutes after
                                                   ${numberText} more unsuccessful log in attempts.`,
                                submitText: 'Forgot Password'
                            }
                        }).afterClosed().subscribe((closedResult: any) => {
                            if (closedResult) {
                                this.sendForgotPass();
                            }
                        });
                    } else if (loadingResult !== true) {
                        this.dialog.open(AppDialogComponent, {
                            width: '350px',
                            autoFocus: false,
                            data: {
                                primaryText: `The email address and/or password you entered doesn't match our records. Please try again.`,
                                submitText: 'Forgot Password'
                            }
                        }).afterClosed().subscribe((closedResult: any) => {
                            if (closedResult) {
                                this.sendForgotPass();
                            }
                        });
                    }
                    this.isLoading = false;
                }
            }, (error: any) => {
                this.isLoading = false;
            });
        }
    }
    /**
     * Send the user to forgotPassword
     */
    sendForgotPass() {
        const navigationExtras: NavigationExtras = {
            queryParams: {
                email: this.emailFormControl.value
            }
        };
        this.router.navigate(['/password-forgot'], navigationExtras);
    }

    loginGoogle() {
        this.isLoading = true;
        this.authService.googleAuth().then(() => this.subscribeToAuth());
    }

    loginApple() {
        this.isLoading = true;
        this.authService.appleAuth().then(() => this.subscribeToAuth());
    }
}
