<div class="outer-flex-container mt-32 mb-32">
  <div class="login-container center pl-16 pr-16 pt-24 pb-24">
    <h4 class="mb-24 mt-24 bold italic">Welcome Back!</h4>

    <mat-form-field class="form-field block mb-16" appearance="outline" hideRequiredMarker>
      <mat-label>Email address</mat-label>
      <input class="email-input" type="email" (keydown)="checkAtSign($event)" [autofocus]="!hasEmail"
        (keydown.enter)="passInput.focus(); showEmailEndings = false" [formControl]="emailFormControl" matInput
        required>
      <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
        Please enter a valid email address
      </mat-error>
      <mat-error *ngIf="emailFormControl.hasError('required')">
        Email is required
      </mat-error>
    </mat-form-field>


    <mat-form-field (click)="showEmailEndings = false" class="form-field block" appearance="outline" hideRequiredMarker>
      <mat-label>Password</mat-label>
      <input [type]="showPass ? 'text' : 'password'" class="email-input" (keydown.enter)="login()"
        [autofocus]="hasEmail" [formControl]="passwordFormControl" #passInput matInput required>
      <mat-error *ngIf="passwordFormControl.hasError('required')">
        Password is required
      </mat-error>
      <mat-icon class="eyeball" matSuffix (click)="showPass = !showPass" *ngIf="!showPass">visibility</mat-icon>
      <mat-icon class="eyeball" matSuffix (click)="showPass = !showPass" *ngIf="showPass">visibility_off</mat-icon>
    </mat-form-field>

    <span class="forgot-pass block fs-14 mt-24 mb-24">Forgot password?
      <span class="clickable muted-blue ml-2" (click)="sendForgotPass()">Reset</span></span>

    <button class="form-field block employer-submit-btn" type="submit" (click)="login()" color="primary"
      mat-raised-button>Log in</button>


    <div class="center mt-24 mb-24">
      <img src="../../../assets/img/login/group-38.svg" />
    </div>
  </div>
</div>

<app-loading *ngIf="isLoading"></app-loading>
